<template>
  <v-app>
    <div class="bg-body d-flex flex-column justify-center align-center min-vh-100 mt-n16">
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-center mb-4">
            <h3 class="mb-0">
              <router-link to="/">
                <v-img
                  width="200"
                  src="@/assets/images/logo.png"
                  class="mx-auto"
                />
              </router-link>
            </h3>
          </div>
          <div
            v-if="changing"
            class="text-center mb-10"
          >
            <h3 class="mb-3">
              {{ message }}
            </h3>
            <p class="mb-2">
              <v-progress-circular indeterminate />
            </p>
          </div>
          <div
            v-else
            class=""
          >
            <p class="red--text text-center">
              {{ error }}
            </p>
          </div>
        </div>
        <v-snackbar
          v-model="isSnackbarBottomVisible"
          :color="snackBarColor"
          :light="$vuetify.theme.dark"
          timeout="5000"
        >
          {{ snackBarMessage }}
        </v-snackbar>
      </div>
    </div>
  </v-app>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import { mapActions, mapMutations } from 'vuex'
  export default {
    components: {
    },
    data () {
      return {
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        valid: false,
        checkbox: false,
        loading: false,
        changing: true,
        isPasswordVisible: false,
        isConfirmPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        error: i18n.t('Unknown payment error'),
        errorMessages: { name: i18n.t('Est requis') },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        confirmPasswordRules: [v => !!v || i18n.t('Le mot de passe de confirmation est requis')],
        token: null,
        id: null,
        message: i18n.t('Processing payment'),
      }
    },
    created () {
      this.token = this.$router.currentRoute.query.data
      this.error = this.$router.currentRoute.query.errorCode
      this.id = this.$router.currentRoute.params.id

      if (this.error) {
        this.error = this.$t('Error occured. Code =') + this.error
        this.message = this.$t('Error occured. Code =') + this.error
        // this.$router.push(`/checkout?paymenterror=${this.error}`)
      }
      this.id = this.$router.currentRoute.params.id
      if (!this.token || this.token.trim().length < 3) {
        this.changing = false
      } else {
        this.onSubmit()
      }
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`
        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      async onSubmit () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/payments',
          data: {
            data: this.token,
            token: this.token,
            id: this.id,
            errorCode: this.$router.currentRoute.query.errorCode,
          },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        })
          .then(async (response) => {
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              // ? Set user's data in localStorage for UI/Other purpose
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                this.errorMessages = { ...response.data.errors }
                this.error = response.data.errors
                this.message = response.data.errors
              } else {
                if (response.data.code) {
                  // this.$router.push(`/checkout?paymenterror=${response.data.message}`)
                }
                this.message = response.data.message
                this.error = response.data.message
                this.isSnackbarBottomVisible = true
                this.snackBarMessage = response.data.message
                this.snackBarColor = this.$vuetify.theme.currentTheme.error
              }
              // this.$router.push(`/checkout?paymenterror=${this.error}&order=${response.data.payment.payable_id}`)
              this.changing = false
              return false
            }
            if (response.data.redirect) {
              this.loading = true
              this.message = this.$t('Validating payment')
               window.location.href = response.data.url
              return
              //var response1 = await axios.get(response.data.url)

              if (response1.data.error) {
                if (response1.data.errors) {
                  // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                  this.errorMessages = { ...response1.data.errors }
                  this.error = response1.data.errors
                  this.message = response1.data.errors
                } else {
                  this.isSnackbarBottomVisible = true
                  this.snackBarMessage = response1.data.message
                  this.snackBarColor = this.$vuetify.theme.currentTheme.error
                  this.error = response1.data.message
                  this.message = response1.data.message
                }
                this.changing = false
                // this.$router.push(`/checkout?paymenterror=${this.error}&order=${response.data.payment.payable_id}`)
                return false
              } else {
                // success
                this.setValue({ type: 'cart', value: { products: [] } })
                localStorage.removeItem('planete@cart')
                this.$router.push('/orders/' + response1.data.id)
              }
            }
            this.isSnackbarBottomVisible = true
            this.snackBarColor = this.$vuetify.theme.currentTheme.success
            this.snackBarMessage = this.$t('Payment confirm')
            if (response.data.type === 'order') {
              // success
              this.setValue({ type: 'cart', value: { products: [] } })
              localStorage.removeItem('planete@cart')
            }

            this.$router.push(response.data.url)
            /* this.message = this.$t('Validating payment')
            var response2 = await axios.get(response.data.url)

            if (response2.data.error) {
              if (response2.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                this.errorMessages = { ...response2.data.errors }
                this.error = response2.data.errors
              } else {
                if (response2.data.code) {
                  this.$router.push('/checkout?paymenterror=' + response2.data.message)
                }
                this.isSnackbarBottomVisible = true
                this.snackBarMessage = response2.data.message
                this.snackBarColor = this.$vuetify.theme.currentTheme.error
                this.error = response2.data.message
              }
              this.changing = false
              this.$router.push('/checkout?paymenterror=' + this.error)
              return false
            } */

            if (response2.data && response2.data.id) {
              this.isSnackbarBottomVisible = true
              this.snackBarColor = this.$vuetify.theme.currentTheme.success
              this.snackBarMessage = this.$t('Payment token confirm')
              // success
              this.setValue({ type: 'cart', value: { products: [] } })
              localStorage.removeItem('planete@cart')
              this.$router.push('/orders/' + response2.data.id)
            } else {
              // success
              this.setValue({ type: 'cart', value: { products: [] } })
              localStorage.removeItem('planete@cart')
              this.$router.push('/orders')
            }
          })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Reset!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media(max-width: 500px){
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem;
    @media(max-width: 500px){
      padding: 3rem 1rem 0px;
    }
  }
}

</style>
